#menuToggle+label {
    display: none;
}

// .topbar_container {
//     box-shadow: 0px 4px 52px 0px rgba(247, 169, 160, 0.18);
// }

.topbar_container .nav_link a {
    font-size: 14px;
    font-weight: 500;
    color: #1C2938;
    text-decoration: none;
}

.close-icon {
    display: none;
}

.submenu-show {
    padding: 14px;
}



@media screen and (max-width: 991px) {


    #menuToggle+label {
        display: block;
    }

    .topbar_container {
        .container-inner {
            flex-wrap: nowrap !important;
        }
    }


    .menu-container {
        position: fixed;
        top: 0;
        right: 0;
        height: 0;
        width: 0;
        z-index: 9999;
        opacity: 0;
        transition: all ease 0.5s;
        background-color: #F8FAFC;
        padding: 0 !important;
        color: black !important;

        .logo-mobile {
            max-width: 70%;
        }

        .nav_link {
            width: 100%;
            line-height: 30px;
            margin: 0 !important;
            text-align: center;
            margin-bottom: 20px !important;

            a {
                color: black;
            }
        }

        .close-icon {
            font-size: 36px;
            font-weight: 700;
            font-family: Arial, Helvetica, sans-serif;
            color: black;
        }

        div {
            display: none;
            // text-align: center;

            img {
                display: none;
            }
        }

        label {
            display: none;
        }
    }

    #menuToggle:checked~.menu-container {
        opacity: 1;
        height: 100%;
        width: 100%;
        padding: 35px 20px !important;

        div {
            display: block !important;

            img {
                display: initial;
            }
        }

        label {
            display: block;
        }
    }
}

@media screen and (max-width: 991px) {
 
    button.font-semibold.p-button-info.login-buttton.p-button.p-component {
        color: #36626B !important;
        // background: #36626B !important;
        border: 1px solid black !important;
    }
}