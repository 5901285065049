.overview-area {
    position: relative;

    .fake-border {
        position: absolute;
        width: calc(100% + 20px);
        height: 40px;
        // background-color: #f80;
        bottom: -20px;
        left: -1px;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: "";
        width: 20px;
        background: $bg-light;
        bottom: -2px;
        border-radius: 0 0 0 20px;
        right: -21px;
        height: 20px;
        z-index: 2;
        border-left: 1px solid var(--surface-400);
        border-bottom: 1px solid var(--surface-400);
    }
}

.filter-menu {
    padding: 10px;
    max-width: fit-content;
    border-radius: 10px;
    margin-top: 10px;
    li {
        border-bottom: 1px solid $color8;
        &:last-child {
            border-bottom: none;
        }
    }
    .p-menuitem-link span {
        color: var(--gray-500) !important;
    }
}

@media only screen and (max-width: 480px) {
    .overview-area {
        position: relative;

        .fake-border {
            display: none;
        }
        &::after {
            display: none;
        }
    }
}
