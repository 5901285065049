.range_value::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #36626b;
    position: absolute;
    bottom: -6px;
    left: 44%;
}
