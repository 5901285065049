.hide-table-lines {
    tr td {
        border: none !important;
    }
}

table {
    border-collapse: collapse;
}

td {
    word-break: break-all;
}

tbody {
    tr:last-child {
        td {
            border-bottom: none !important;
        }
    }
}

.first-caps {
    text-transform: capitalize;
}

.focus-table {
    thead {
        th {
            .p-column-title {
                color: rgba(54, 98, 107, 1) !important;
            }
            &:nth-child(1){
                width: 150px;
            }
        }
    }
    tbody{
        td {
            border-right: 1px solid rgba(238, 238, 238, 1) !important;
            border-bottom: 1px solid rgba(238, 238, 238, 1) !important;
            padding: 20px !important;
        }
    }
}
.employee-focus-table{
    thead {
        th {
           border: none !important;
        }
    }
    tbody{
        td {
            border-right: none !important;
            border-bottom: none !important;
            padding: 20px !important;
        }
    }
}