.faq-section {
    .p-accordion .p-accordion-tab {
        margin-bottom: 20px !important;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link,
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
        flex-direction: initial !important;
        justify-content: flex-start !important;
        padding-left: 40px;
        gap: 20px;
        font-weight: 600 !important;
    }
    .p-accordion-content {
        padding-left: 80px;
        padding-top: 0;
        background: $color1;
        p {
            color: #fff !important;
        }
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
        background: #fff !important;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: $color1 !important;
        border-bottom: 0;
        color: #fff !important;
    }
}
