.cover-img {
    object-fit: cover;
}

.sub-heading-divider:before,
.sub-heading-divider:after {
    content: "";
    border: 1px solid #E8E8E8;
    position: absolute;
    width: 45%;

}

.sub-heading-divider:before {
    top: 10px;
    left: 0px;
}

.sub-heading-divider:after {
    top: 10px;
    right: 0px;
}

.right-side-sinup {
    min-height: 80vh;
}

@media screen and (max-width:567px) {
    .left-layout {
        height: 300px;
        width: 100%;

    }
    .right-side-sinup {
        min-height: 50vh;
    }
}