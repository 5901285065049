.dashboard-detail-container {
    min-height: 93vh;
    height: 88vh;
}
.dashboard-detail-container::-webkit-scrollbar {
    display: none;
}

body:has(.filter-menu) .dashboard-detail-container {
    overflow: hidden !important;
}
