@media only screen and (min-width: 1040px) {
    .survey-input {
        max-width: 18%;
        padding: 0;
        margin: 0 !important;
    }
}
.label-button {
    box-shadow: 0px 1px 2px 0px #1018280d;
    border: 1px solid #e8e8e8;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.p-radiobutton-checked + .label-button {
    background-color: #66c2cb;
    color: #ffffff;
}
.react-tel-input .form-control {
    border: 1px solid $color8 !important;
    font-size: 0.875rem;
    padding: 0.75rem 3.75rem;
    height: 41px;
}
.react-tel-input .flag-dropdown {
    background-color: $color8;
}
.custom-csv-upload .p-fileupload {
    display: flex;
    flex-direction: column-reverse;
    border: 2px dashed var(--color8);
    border-radius: 10px;
    padding: 2rem;

    .p-fileupload-content {
        border: none;
        .p-progressbar {
            background: none;
        }
    }
}
