// Please write exact color name
:root {
    --color1: #66C2CB;
    --color2: #AFAFAF;
    --color3: #EDFAFF;
    --color4: #E8F2FF;
    --color5: #E1F6FB;
    --color6: #F8F8F8;
    --color7: #ECFDFF;
    --color8: #E8E8E8;
    --color9: #36626B;
    --bg-light: #F8FEFF;
}


/////////////////////////////////////////////////////////////////
///////////// Follow the same color name below//////////////////
///////////////////////////////////////////////////////////////

// These variables with $ sign will be used in all other style file
// No color code will be write in any style file directly

$color1: var(--color1);
$color2: var(--color2);
$color3: var(--color3);
$color4: var(--color4);
$color5: var(--color5);
$color6: var(--color6);
$color7: var(--color7);
$color8: var(--color8);
$color9: var(--color9);
$bg-light: var(--bg-light);
$headingcolor: var(--text-color);
$subheadingcolor: var(--surface-b);
$successcolor: var(--green-500);
$primarycolor: var(--primary-color);
