.bg-skyblue {
    background-color: $color1 !important;
}
.bg-gray {
    background-color: $color2 !important;
}
.bg-lightest-blue {
    background-color: $color3;
}
.bg-lightest-voilet {
    background-color: $color4;
}
.bg-light-green {
    background-color: $color5;
}
.bg-lightest-color {
    background-color: $color6;
}
.bg-lightest-green {
    background-color: $color7;
}
.bg-light {
    background-color: $bg-light;
}
