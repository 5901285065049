.toggle-menu {
    background: $color1;
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: -13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    left: -12px;
}

.topbar-container {
    height: 83px;
}

.topbar-inner-container {
    width: 90%;
    max-width: 1440px;
    margin: auto;
}

.logo-container {
    width: 211px;
    height: 45px;

    img {
        width: 100%;
        height: 100%;
    }
}

// .profile-img-container {
//     width: 42px;
//     height: 42px;
//     border-radius: 50%;
//     overflow: hidden;
//     background: #ccc;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     img {
//         width: 100%;
//         height: 100%;
//     }
// }

@media screen and (max-width: 567px) {
    .logo-container img {
        width: 80% !important;
        height: 80% !important;
    }
}
