@import "./assets/styles/all.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    background-color: $bg-light;
}

div::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px var(--primary-color);
    // background-color: #f5f5f5;
    background-color: none;
}
div::-webkit-scrollbar {
    width: 7px;
    height: 3px;
    // background-color: #f5f5f5;
}

div::-webkit-scrollbar-thumb {
    // -webkit-box-shadow: inset 0 0 6px var(--primary-color);
    border-radius: 24px;
    background-color: #EAEAEA;
}
.container {
    width: 90%;
    max-width: 1440px;
    margin: auto;
}

.border {
    border: 1px solid var(--surface-400);
    border-radius: 0.75rem;
}
hr {
    border-color: var(--surface-400);
}
h2,
h3,
.p-column-title {
    font-weight: 600;
    color: var(--gray-900);
}
p,
td {
    color: var(--gray-500);
}
.p-column-title,
td {
    font-size: 14px;
}

.p-calendar input,
.p-inputnumber-input {
    font-size: 0.875rem !important;
}
.p-calendar {
    button {
        padding: 0 !important;
        border-radius: 0 0.5rem 0.5rem 0;
    }
    input {
        border-radius: 0.5rem 0 0 0.5rem !important;
    }
}

.p-inputnumber-input {
    border-radius: 0.5rem;
}
.p-accordion-tab.p-accordion-tab-active {
    svg {
        transform: rotate(180deg);
    }
}
.p-accordion-tab {
    svg {
        transform: rotate(90deg);
    }
}

.specialty-container .p-chip {
    background-color: #e8fbff;
    color: #36626b;
}
.border-custom:nth-last-child(1){
    border-bottom: none;
    margin-bottom: 0 !important;
}