.overview-img {
    width: 68px;
    height: 68px;
    border-radius: 50%;
}
.survey-img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}
.survay-box .p-card-content {
    padding: 0;
}
.discount-style {
    width: 110px;
    height: 112px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    overflow: hidden;
    top: 0;

    div {
        padding: 9px 12px;
        color: #ffffff;
        position: inherit;
        text-align: center;
        background-color: var(--color9);
        transform: rotate(45deg);
        width: 183%;
        margin: 0px 0 31px 0px;
        left: -34px;
        font-size: 14px;
    }
}
.improvement-img{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    img{
        object-fit: cover;
        width: 65px;
        height: 65px;
        
    }
}