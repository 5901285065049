.select-role-box {
    .role-card {
        width: 291px;
        height: 198px;
        display: flex;
        align-items: end;
        justify-content: center;
        gap: 0px;
        border-radius: 2.93px;

        &.active{
            box-shadow: 0px 0px 20px #fff;
        }
        img {
            width: 40% ;
        }
    }
    &:nth-last-child(1){
        .role-card{
            img{
                width: 200px;
            }
        }
    }
}