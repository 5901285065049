.heading-color {
    color: $headingcolor !important;
}

.sub-heading {
    color: $subheadingcolor;
}

.text-skyblue {
    color: $color1;
}

.text-success {
    color: $successcolor;
}

.text-gray-light {
    color: $color2;
}
.text-dark {
    color: var(--text-color);
}
.text-blue {
    color: var(--color1);
}

.text-main-color {
    color: $color9 !important;
}
