.side_menuitem.active {
    background-color: $bg-light ;
    border-radius: 50px 0 0 50px;
    color: var(--primary-color);
    img{
        filter: none;
    }
}

.side_menuitem {
    color: $color2;

    img{
        filter: grayscale(100%) brightness(200%);
    }
}

.sidebar-nav {
    border-radius: 0 25px 25px 0;
}

.sidebar-nav::-webkit-scrollbar {
    display: none;

}
.sidebar-nav{
    li, li a{
        position: relative;
    }
    li:has(a.active):before, li:has(a.active):after, li a.active::before, li a.active::after{
        position: absolute;
        content: "";
        width: 30px;
        height: 50px;
        right: 0;
    }
    li:has(a.active):before, li a.active::before{
        top: -50px;
    }
    li:has(a.active):after, li a.active::after{
        bottom: -50px;
    }
    li:has(a.active):before, li:has(a.active):after{
        background: var(--primary-color);
        z-index: 2;
    }
    li:has(a.active):before{
        border-bottom-right-radius: 50px;
    }
    li:has(a.active):after{
        border-top-right-radius: 50px;
    }
    li a.active::before, li a.active::after{
        background: var(--bg-light);
        z-index: 1;
    }
}
