.skip-boarding {
    text-decoration: underline;
    right: 0;
    cursor: pointer;
}
.back-btn-boarding {
    left: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.onboarding-page {
    .p-menuitem-link:focus {
        box-shadow: none !important;
    }

    .p-steps-item .p-menuitem-link {
        background: transparent;
    }

    .p-steps-item::before {
        // width: 50%;
        border-top: 1px dashed #dee2e6;
    }

    .p-steps-item:first-child:before {
        left: 50%;
    }
    .p-steps-item:last-child:before {
        width: 50%;
    }
    .p-steps-item.p-highlight .p-steps-number {
        background: $bg-light;
        color: var(--color1);
        border: 1px solid var(--color1);
    }
}

.success-screen {
    min-height: calc(100vh - 85px);
}

@media screen and (max-width: 567px) {
    .category-overview-card {
        width: 100% !important;
    }
}
